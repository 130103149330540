import React from "react"
import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import lead_manage from "../banner/static/lead_manag.webp"
import * as classes from "./leadership_management_training.module.scss"
import loadable from "@loadable/component"
const Banner = loadable(() => import("../banner/banner"), {
  fallback: <div></div>,
})

export default function LeadershipManagement() {
  return (
    <React.Fragment>
      <MainHeader />
      <Banner
        title={"Leadership Management Training"}
        backgroundImage={lead_manage}
      />
      <div className={classes.text_div}>
        <h2>Leadership Management Training</h2>
        <p>
          We've compiled a list of strategic skills and tools every leader
          should have in their toolbox to effectively handle any situation that
          may arise. Strategic thinking, decision making skills, conflict
          resolution skills, delegation skills and conflict resolution skills
          are some of the core features necessary to a leader. Building on this
          foundation we extended the training to also cover emotional
          intelligence and innovation, because it’s absolutely vital to adapt to
          the changes in environment as well as emotion. A leader is responsible
          for their entire team so we make sure to cover how to boost employee
          performances and make sure that everyone under your lead is working to
          their full potential. You will also be responsible for creating the
          business culture at work so we will ensure you know how to curate a
          culture that everyone will love to work in. Applying all these will
          allow you to quickly lead your team to the next level.
        </p>
        <p>Leadership management training is for:</p>
        <ul>
          <li>Experienced workers </li>
          <li>Managers </li>
          <li>Business owners </li>
          <li>Division heads </li>
        </ul>
      </div>
      <MainFooter />
    </React.Fragment>
  )
}
